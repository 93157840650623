import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { BackgroundPath } from '../components/ClipPath/BackgroundPath';
import { SharingIcons } from '../components/SharingIcons';
import { Helmet } from 'react-helmet';

export default function ({ data, pageContext: { locale, video360 } }: any) {
  const [node] = data.allContentfulAbout.nodes;
  const [{ x, y, scale }, setOffset] = useState({ x: 0, y: 0, scale: 1 });

  useEffect(() => {
    let scaleFactor = Math.max(
      window.innerWidth / 1920,
      window.innerHeight / 1080
    );

    setOffset({
      x: (window.innerWidth - 1920 * scaleFactor) / 2,
      y: (window.innerHeight - 1080 * scaleFactor) / 2,
      scale: scaleFactor,
    });
    window.addEventListener('resize', () => {
      scaleFactor = Math.max(
        window.innerWidth / 1920,
        window.innerHeight / 1080
      );
      setOffset({
        x: (window.innerWidth - 1920 * scaleFactor) / 2,
        y: (window.innerHeight - 1080 * scaleFactor) / 2,
        scale: scaleFactor,
      });
    });

    return () =>
      window.removeEventListener('resize', () =>
        setOffset({
          x: (window.innerWidth - 1920 * scaleFactor) / 2,
          y: (window.innerHeight - 1080 * scaleFactor) / 2,
          scale: scaleFactor,
        })
      );
  }, []);

  const { description } = node;
  return (
    <>
      <Helmet>
        <title>Edge Fx - About</title>
        <meta name="title" content="Edge Fx - About" />
        <meta
          name="description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://edgefx.tv/about" />
        <meta property="og:title" content="Edge Fx - About" />
        <meta
          property="og:description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta
          property="og:image"
          content="https://edgefx.tv/EdgeFxPreview.jpg"
        />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="300" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edgefx.tv" />
        <meta property="twitter:url" content="https://www.edgefx.tv/" />
        <meta name="twitter:title" content="Edge Fx" />
        <meta
          name="twitter:description"
          content="EdgeFx is a creative studio in Italy specializes in cg, vfx and visual effects delivering high quality and photorealistic contents."
        />
        <meta
          name="twitter:image"
          content="https://edgefx.tv/EdgeFxPreview.jpg"
        />
      </Helmet>
      <div
        className={'chevron-control'}
        onClick={() => {
          navigate(`${locale === 'it' ? '/' : '/en/'}`);
        }}
      >
        <svg
          enableBackground="new 0 0 515.556 515.556"
          height="24"
          viewBox="0 0 515.556 515.556"
          width="24"
        >
          <path
            stroke="#fff"
            fill="#fff"
            d="m128.885 257.778 257.778-257.778v128.886l-128.889 128.892 128.897 128.886-.008 128.892z"
          />
        </svg>
      </div>
      <div className={'intro-container'}>
        <div
          style={{
            position: 'absolute',
            left: x,
            top: y,
            transform: `scale(${scale})`,
            transformOrigin: '0 0',
          }}
        >
          <BackgroundPath locale={locale} />
        </div>
      </div>
      <div className="contact-area contact-area-about">
        <div className={'footer-contact-block footer-contact-block-about'}>
          <h3>General Inquires</h3>
          <a href={'mailto:info@edgefx.tv'}>
            <p>info@edgefx.tv</p>
          </a>

          <h3>Telephone</h3>
          <a href={'mailto:info@edgefx.tv'}>
            <p>+39 3403153593</p>
          </a>

          <h3>Social</h3>
          <SharingIcons about />
        </div>
      </div>

      <div className="info-area">
        {description?.description && (
          <ReactMarkdown source={description?.description} escapeHtml={false} />
        )}
      </div>
    </>
  );
}

export const query = graphql`
  query($locale: String!) {
    allContentfulAbout(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        description {
          description
        }
      }
    }
  }
`;
