import React from 'react';
import { navigate } from 'gatsby';

export const BackgroundPath = ({ locale }) => {
  return (
    <div className="layout-container">
      <svg viewBox="0 0 1920 1080">
        <defs>
          <pattern
            id="bg-image"
            patternUnits="userSpaceOnUse"
            width="100%"
            height="100%"
          >
            <image
              href="/Sfondo_sito.jpg"
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserveAspectRatio="none"
            />
          </pattern>

          <linearGradient
            id="linear-gradient"
            x1="594.59"
            y1="640.72"
            x2="-392.04"
            y2="-1741.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" stopOpacity="0.1" />
            <stop offset="0.41" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="1142.29"
            y1="168.97"
            x2="4736.67"
            y2="1657.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff" stopOpacity="0.1" />
            <stop offset="0.3" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="-1854.04"
            y1="2871.04"
            x2="741.12"
            y2="275.88"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.69" stopColor="#fff" stopOpacity="0" />
            <stop offset="1" stopColor="#fff" stopOpacity="0.1" />
          </linearGradient>
        </defs>
        <title>Artboard 1</title>
        <path
          // fill={'url(#linear-gradient)'}
          fill="url(#bg-image)"
          d="M-2087-1050V474.5H957.8A59.61,59.61,0,0,0,1000,457L2507-1050Z"
        />
        <path
          // fill={'url(#linear-gradient-2)'}
          fill="url(#bg-image)"
          d="M4326.5-1049.5h-1781l-.45.5L1020,476a59.61,59.61,0,0,0-17.46,42.16V2648.5l3324-.44Z"
        />
        <path
          // fill={'url(#linear-gradient-3)'}
          fill="url(#bg-image)"
          d="M-2087.5,502.5H949.17a23.33,23.33,0,0,1,23.33,23.33V2646.5a0,0,0,0,1,0,0h-3060a0,0,0,0,1,0,0V502.5A0,0,0,0,1-2087.5,502.5Z"
        />
        <text x={770} y={585} className={'slogan-intro'}>
          3D creative studio
        </text>
        <g
          transform="translate(766.000000, 441.000000)"
          onClick={() => {
            navigate(`${locale === 'it' ? '/' : '/en/'}`);
          }}
        >
          <path
            fill={'white'}
            d="M35.7,121.26 L35.7,114.26 L10.58,114.26 C8.98941992,114.26 7.7,112.97058 7.7,111.38 L7.7,96.13 C7.70550932,94.5433266 8.99331699,93.26 10.58,93.26 L35.7,93.26 L35.7,86.26 L8.54,86.26 C4.2139898,86.26 0.70551787,89.7639933 0.7,94.09 L0.7,113.43 C0.70551787,117.756007 4.2139898,121.26 8.54,121.26 L35.7,121.26 Z"
          ></path>
          <polygon
            fill={'white'}
            points="14.7 107.26 35.7 107.26 35.7 100.26 7.7 100.26"
          ></polygon>
          <path
            fill={'white'}
            d="M39.7,121.26 L66.87,121.26 C71.1943896,121.26 74.7,117.75439 74.7,113.43 L74.7,94.09 C74.7,92.0133549 73.8750559,90.0217637 72.4066461,88.5533539 C70.9382363,87.0849441 68.9466451,86.26 66.87,86.26 L46.7,86.26 L53.7,93.26 L64.83,93.26 C66.4150572,93.26 67.7,94.5449428 67.7,96.13 L67.7,111.38 C67.7026582,112.142902 67.401458,112.87547 66.8629402,113.415864 C66.3244224,113.956258 65.5929071,114.26 64.83,114.26 L46.7,114.26 L46.7,86.26 L39.7,86.26 L39.7,121.26 Z"
          ></path>
          <path
            fill={'white'}
            d="M106.7,114.26 L106.7,107.26 L113.7,100.26 L113.7,121.26 L86.54,121.26 C82.2139898,121.26 78.7055179,117.756007 78.7,113.43 L78.7,94.09 C78.7055179,89.7639933 82.2139898,86.26 86.54,86.26 L113.7,86.26 L113.7,93.26 L88.58,93.26 C86.993317,93.26 85.7055093,94.5433266 85.7,96.13 L85.7,111.38 C85.7,112.97058 86.9894199,114.26 88.58,114.26 L106.7,114.26 Z"
          ></path>
          <path
            fill={'white'}
            d="M152.7,121.26 L152.7,114.26 L127.58,114.26 C125.98942,114.26 124.7,112.97058 124.7,111.38 L124.7,96.13 C124.705509,94.5433266 125.993317,93.26 127.58,93.26 L152.7,93.26 L152.7,86.26 L125.54,86.26 C121.21399,86.26 117.705518,89.7639933 117.7,94.09 L117.7,113.43 C117.705518,117.756007 121.21399,121.26 125.54,121.26 L152.7,121.26 Z"
          ></path>
          <polygon
            fill={'white'}
            points="131.7 107.26 152.7 107.26 152.7 100.26 124.7 100.26"
          ></polygon>
          <path
            fill={'white'}
            d="M174.92,86.15 L174.92,89 L165.64,89 C162.61348,89 160.16,91.4534796 160.16,94.48 L160.16,102.19 L174.88,102.19 L182.32,109.63 L189.76,102.19 L191.76,104.19 L184.32,111.63 L191.76,119.07 L189.76,121.12 L182.32,113.68 L174.88,121.12 L172.88,119.07 L180.32,111.63 L173.73,105.04 L160.16,105.04 L160.16,121.2 L157.27,121.2 L157.27,93.9 C157.27,89.6197932 160.739793,86.15 165.02,86.15 L174.92,86.15 Z"
          ></path>
          <path
            fill={'white'}
            className={'logo-intro'}
            d="M269.1,5.89 C268.65,6.37 267.91,7.15 266.99,8.1 C248.14,27.86 244.68,30.1 238.99,38 C236.05,42.11 232.27,46.73 229.99,54 C227.83686,60.818691 226.494279,67.8672401 225.99,75 C224.67,105.8 226.12,134.2 225.99,164.61 C225.99,164.87 225.99,166.74 225.99,167 L217.99,167 L217.99,75 C217.99,74.52 217.99,73.83 217.99,73 C217.89,70.88 217.22,62.71 210.99,57 C205.84,52.29 199.46,51.59 193.99,51 C192.68,50.86 191.58,50.8 190.83,50.78 C161.716667,50.9333333 132.606667,51.0066667 103.5,51 L102,51 L102,43 L194,43 C195,43 196.38,43.06 198,43 C207.531518,42.6583351 216.820859,39.9059376 225,35 C225.58,34.65 226.4,34.14 227.49,33.41 C236.36,27.48 250.41,13.26 261.42,2.51 L263.56,0.42 C264.48,1.33 265.4,2.25 269.1,5.89 Z"
          ></path>
        </g>
      </svg>
    </div>
  );
};
